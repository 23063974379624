<template>
  <div class="main-content q-pa-md">
    <Mural
      :notificationQuantity="wiki_count"
      :breadcrumbs="breadcrumbs"
      route="home"
      :query="{}"
    >
      <DefaultBtn v-if="btnAddWiki" titleButton="Nova Wiki" route="new-wiki" />
    </Mural>

    <div class="list-training q-mt-md">
      <div class="flex justify-center centers">
        <div>
          <h6>Procure por uma wiki</h6>
          <q-select
            outlined
            dense
            bg-color="grey-3"
            color="default-pink"
            style="width: 350px"
            use-input
            input-debounce="0"
            @filter="filterFnWikis"
            label="Informe o título da wiki"
            v-model="titleWiki"
            :options="listWikis"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:option="{ itemProps, opt }">
              <q-item
                style="width: 350px"
                v-bind="itemProps"
                class="search-options options"
              >
                <q-item-section avatar>
                  <q-img
                    :src="url_aws_bucket + opt.banner"
                    v-if="opt.banner != null"
                    class="image-training"
                    height="70px"
                    width="55px"
                  />
                  <q-img
                    src="../../assets/images/banner_default.png"
                    v-else
                    class="image-training"
                    height="70px"
                    width="55px"
                  />
                </q-item-section>
                <q-item-section @click="accessWiki(opt.wikiId)">
                  <q-item-label
                    ><b
                      ><u>{{ opt.title }}</u></b
                    ></q-item-label
                  >
                  <q-item-label caption lines="2" v-html="opt.description" />
                </q-item-section>
                <q-item-section v-if="defineAction(opt.userId)" side>
                  <q-btn
                    round
                    dense
                    size="md"
                    color="default-pink"
                    :to="{ name: 'new-wiki', query: { wikiId: opt.wikiId } }"
                  >
                    <q-icon name="edit_note" size="sm" />
                  </q-btn>
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
      </div>

      <div class="form-input col-12 row items-end">
        <h6 class="q-mt-lg border-padding">Mais acessados</h6>
        <!-- <div class="row">
          <span class="title-input-form">Filtro </span>
        </div> -->
        <div class="row">
          <!-- <q-select
            outlined
            style="width: 350px"
            bg-color="grey-3"
            color="default-pink"
            class="col-11"
            dense
            label="Informe uma categoria"
            emit-value
            map-options
            v-model="category"
            :option s="listCategories"
          />
          <DefaultBtn
            :isDense="true"
            :isInverse="true"
            :showIcon="false"
            :hasPadding="false"
            class="q-ml-sm"
            titleButton="Filtrar"
            @click="getAllWikis(true)"
          /> -->
          <q-btn
            flat
            rounded
            color="default-pink"
            size="12px"
            v-if="btnAddWiki"
          >
            <q-img width="20px" :src="filterimg" spinner-color="white" />
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup>
                  <q-item-section @click="handleFilterlistUers(0)">
                    <p>Inativos</p>
                  </q-item-section>
                </q-item>
                <q-item
                  clickable
                  v-close-popup
                  @click="handleFilterlistUers(1)"
                >
                  <q-item-section>
                    <p>Ativos</p>
                  </q-item-section>
                </q-item>
                <q-item
                  clickable
                  v-close-popup
                  @click="handleFilterlistUers(2)"
                >
                  <q-item-section>
                    <p>Todos</p>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>

      <WikiCard
        :key="wiki.wikiId"
        v-for="wiki in wikis"
        :wiki="wiki"
        :image="url_aws_bucket + wiki.banner"
      />
      <div class="flex justify-center centers q-mt-sm">
        <DefaultBtn
          :isDense="true"
          :isInverse="true"
          :showIcon="false"
          :hasPadding="false"
          titleButton="Carregar wikis"
          @click="getAllWikis()"
        />
      </div>
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div class="collumn justify-center q-my-md">
        <div style="height: auto; width: 50px; margin: auto">
          <LoadingIcon />
        </div>
        <label>Por favor aguarde...</label>
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
//----IMAGES
import filterimg from "@/assets/icons/filter.svg";

//----COMPONENTS
import Mural from "@/components/Mural.vue";
import WikiCard from "@/components/wiki/cards/WikiCard.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
// import SearchWiki from "@/components/wiki/inputs/SearchWiki.vue";

//----SERVICES
import WikiDataServices from "@/services/WikiDataServices";

//----UTILS
import { ENV  } from "@/utils/env";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

//----QUASAR
import { useQuasar } from "quasar";

//----VUEJS
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "wiki",
  components: {
    Mural,
    WikiCard,
    DefaultBtn,
    LoadingIcon,
  },
  setup() {
    //----CONSTANTES
    const store = useStore();
    const router = new useRouter();
    const listCategories = new ref([]);
    const $q = new useQuasar();
    const breadcrumbs = ref(["Wikis"]);
    const canUserAccessClass = new CanUserAccessClass();
    const wikiDataServices = new WikiDataServices();
    const url_aws_bucket =ENV.URL_AWS_BUCKET;
    const pageSize = ref(5);

    let loading = ref(false);
    let titleWiki = ref(null);
    let wikis = ref([]);
    let listWikis = ref([]);
    let wiki_count = ref(0);
    let category = ref(null);
    let pageNumber = ref(1);
    let btnAddWiki = ref(true);

    onMounted(() => {
      ValidateActionsPage();
      getAllWikis();
      listAllCategories();
    });

    function defineAction(id) {
      let userData = JSON.parse(localStorage.getItem("vuex")).userdata;
      return userData.id == id;
    }

    async function getAllWikis(emptyWikis = false) {
      loading.value = true;

      if (emptyWikis) {
        wikis.value = [];
        pageNumber.value = 1;
      }

      let data = [];
      if (category.value == null)
        data = await wikiDataServices.getAll(pageNumber.value, pageSize.value);
      else
        data = await wikiDataServices.getForCategory(
          category.value,
          pageNumber.value,
          pageSize.value
        );

      if (data.data != null) {
        pageNumber.value = pageNumber.value + 1;
        data.data.forEach((wiki) => {
          wikis.value.push(wiki);
        });
        wiki_count.value = wiki_count.value + data.data.length;
      }

      loading.value = false;
    }

    async function getAllWikisInactive() {
      loading.value = true;

      let data = [];

      data = await wikiDataServices.getAllInactive();
      wikis.value = [];
      if (data.data != null) {
        wikis.value.push(...data.data);
        wiki_count.value = wiki_count.value + data.data.length;
      }

      loading.value = false;
    }

    async function getAll(emptyWikis = false) {
      loading.value = true;

      if (emptyWikis) {
        wikis.value = [];
        pageNumber.value = 1;
      }

      let active = await wikiDataServices.getAll(
        pageNumber.value,
        pageSize.value
      );
      let inactive = await wikiDataServices.getAllInactive();

      wikis.value = [];
      wikis.value.push(...active.data);
      wikis.value.push(...inactive.data);
      wiki_count.value = wiki_count.value + wikis.value.length;
      pageNumber.value = pageNumber.value + 1;

      loading.value = false;
    }

    async function handleFilterlistUers(value) {
      const WIKI_INACTIVE = 0;
      const WIKI_ACTIVE = 1;
      const ALL_WIKIS = 2;

      switch (value) {
        case WIKI_INACTIVE:
          await getAllWikisInactive();
          return;

        case WIKI_ACTIVE:
          await getAllWikis(true);
          return;

        case ALL_WIKIS:
          getAll(true);
          return;
        default:
          await getAllWikis(true);
          return;
      }
    }

    async function listAllCategories() {
      wikiDataServices
        .getAllCategories(store.state.userdata.company_id)
        .then((response) => {
          response.data.map((item) => {
            listCategories.value.push({
              label: item.name,
              value: item.id,
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    async function ValidateActionsPage() {
      if (!canUserAccessClass.canUserAccess("new-wiki", false)) {
        btnAddWiki.value = false;
        return;
      }
    }

    async function searchWikiForTitle() {
      let title = titleWiki.value;
      if (title == null) return;

      loading.value = true;
      let data = await wikiDataServices.getForTitle(title).catch((error) => {
        alertErro(error.response.data.toString());
      });
      loading.value = false;
      wikis.value = data.data;
    }

    function alertErro(message) {
      $q.notify({
        message: message,
        color: "red-9",
        position: "top",
      });
      loading.value = false;
    }

    async function filterFnWikis(val, update) {
      if (val === "") {
        update(() => {
          listWikis.value = [];
        });
        return;
      }
      if (val.length >= 3) {
        let dataWikiSearch = await wikiDataServices
          .getForTitle(val)
          .catch((error) => {
            alertErro(error.response.data.toString());
          });
        update(() => {
          listWikis.value = dataWikiSearch.data;
        });
      }
    }

    function accessWiki(wikiId) {
      titleWiki.value = "Por favor, aguarde...";
      router.push({ name: "wiki-explorer", query: { wikiId: wikiId } });
    }

    return {
      //----IMAGEGS
      filterimg,

      titleWiki,
      getAllWikis,
      wikis,
      searchWikiForTitle,
      accessWiki,
      category,
      listCategories,
      filterFnWikis,
      loading,
      wiki_count,
      btnAddWiki,
      listWikis,
      url_aws_bucket,
      breadcrumbs,
      defineAction,
      handleFilterlistUers,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-options {
  padding-left: 1px;
  padding-top: 1px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ccc;
}
.border-padding {
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
.main-content {
  width: 100%;
  height: 100%;

  .list-training {
    overflow: auto;
    height: 75vh;
    padding: 1%;
  }

  hr {
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    border: none;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
